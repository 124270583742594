.bw {
  filter: grayscale(100%);
}

div.discord::-webkit-scrollbar {
  display: none;
}

div.discord {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hoverG:hover {
  animation: hoverGuild 300ms;
}

@keyframes hoverGuild {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}